<template>
  <section class="top-filter">
    <div class="mb12">
      <div ref="filterContainerRef" class="filter-layout">
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <a-form-item v-show="formState.groupLead" label="Group Lead" name="groupLead" class="rms-form-item">
            <a-input v-model:value="formState.groupLead" disabled/>
          </a-form-item>
          <a-form-item label="City" name="city" class="rms-form-item">
            <a-select v-model:value="formState.city" :options="cityOps" placeholder="请选择" @change="selectChangeHandler('city')" >
            </a-select>
          </a-form-item>
          <a-form-item label="Building" name="building" class="rms-form-item">
            <a-select v-model:value="formState.building" :options="buildingOps" placeholder="请选择" @change="selectChangeHandler('building')">
            </a-select>
          </a-form-item>
          <a-form-item label="Floor" name="floor" class="rms-form-item">
            <a-select v-model:value="formState.floor" :options="floorOps" placeholder="请选择" @change="selectChangeHandler('floor')">
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted, onActivated,
} from "vue";
import {useGlobalPropertyHook} from '@/hooks/common'
import {useStore} from 'vuex'

export default defineComponent({
  name: "TopFilter",
  components: {},
  emits: ["search"],
  props: {
    addFlag:{
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const userInfo = store.state.app.user
    let initData = {
      groupLead: "",
      city: "",
      building: "",
      floor: "",
    }
    const formState = reactive({...initData});
    const formRef = ref();
    const selectPersonSeat = async () => {
      await $api.selectPersonSeat().then((res) => {
        const data = res.data
        let office = data.mySeatCommandList[0].office.split('/')
        formState.groupLead = data.mySeatCommandList[0].groupLeadId!==userInfo.id?data.mySeatCommandList[0].groupLeadName+'('+data.mySeatCommandList[0].groupLeadEmail+')':''
        formState.city = office[0]
        formState.building = office[1]
        formState.floor = office[2]
        ctx.emit("search", Object.assign({}, data, formState));
      });
      await queryBaseData()
    };

    const cityOps = ref([])
    const buildingOps = ref([])
    const floorOps = ref([])

    // 下拉筛选全部List(没有进行联动)
    const originList = {}

    const queryBaseData = () => {
      $api.queryBaseData({ "types": ["city", "work_location", "floor"] }).then((res) => {
        const data = res.data
        let city = data.filter((item) => { return item.type == "city" })
        let building = data.filter((item) => { return item.type == 'work_location' })
        let floor = data.filter((item) => { return item.type == 'floor' })
        handlerSelectOption(city, building, floor)
      });
    };
    // 注意使用下列函数格式化时候，传入的顺序不能变
    const handlerSelectOption = (city, building, floor) => {
      let mapData = (data) => {
        return data.map((item) => {
          return {
            ...item,
            value: item.code,
            label: item.name
          }
        })
      }
      originList['city'] = mapData(city)
      originList['building'] = mapData(building)
      originList['floor'] = mapData(floor)
      cityOps.value = originList['city']
      let buildingCode = originList['city'].filter((item) => item.label === formState.city)[0].code
      buildingOps.value = originList['building'].filter((jtem) => { return [buildingCode].includes(jtem.uplevel) })
      let floorCode = originList['building'].filter((item) => item.label === formState.building)[0].code
      floorOps.value = originList['floor'].filter((jtem) => { return [floorCode].includes(jtem.uplevel) })
    }
    // 联动city，building，floor
    const selectChangeHandler = (item) => {
      if (item == 'city') {
        if (formState[item] && formState[item].length) {
          buildingOps.value = originList.building.filter((jtem) => { return [formState[item]].includes(jtem.uplevel) })
          formState.building = undefined
        } else {
          buildingOps.value = []
          formState.building = undefined
          floorOps.value = []
          formState.floor = undefined
        }
      }
      if (item == 'building') {
        if (formState[item] && formState[item].length) {
          floorOps.value = originList.floor.filter((jtem) => { return [`${formState[item]}`].includes(jtem.uplevel) })
          formState.floor = undefined
        } else {
          floorOps.value = []
          formState.floor = undefined
        }
      }
      if (item == 'floor') {
        const params = {
          city: cityOps.value.filter(item => item.label===formState.city||item.code===formState.city )[0].code,
          building: buildingOps.value.filter(item => item.label===formState.building||item.code===formState.building)[0].code,
          floor:formState.floor,
        }
        $api.selectPersonSeatByOffice(params).then((res) => {
          formState.groupLead = (res.data.mySeatCommandList &&
              res.data.mySeatCommandList[0].groupLeadId!==userInfo.id)?
              res.data.mySeatCommandList[0].groupLeadName+'('+res.data.mySeatCommandList[0].groupLeadEmail+')' : ''
          ctx.emit("search", Object.assign({}, res.data, formState, {type:1},));
        });
      }
    }

    onMounted( ()=>{
      selectPersonSeat()
    })
    onActivated(()=>{
    })
    return {
      formState,
      formRef,
      cityOps,
      buildingOps,
      floorOps,
      originList,
      queryBaseData,
      handlerSelectOption,
      selectChangeHandler,
    };
  },
});
</script>
<style scoped lang="less">
:deep(.ant-form-item-control) {
  width: 100%;
}
</style>
