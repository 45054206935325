<template>
  <section class="app-container" ref="seatApplicationRef">
    <Filter
        v-show="isExist"
        ref="seatFilterRef"
        @search="handleSearch">
    </Filter>
    <div v-if="isExist" class="content">
      <div class="content-l">
        <div class="left">
          <span class="icon icon_seat_green">已选座位</span>
          <span class="icon icon_seat_white_border">安全隔离座位</span>
          <span class="font-gray">安全隔离区域仅限涉密项目组使用，非使用者请勿随意出入或占用。</span>
        </div>
        <div class="seat-box">
          <SeatMaps :type="'2'" :locationName="locationName" :selectedData="seatArr" :seatData="seatData"></SeatMaps>
          <div class="lastUpdateTime">如需续期或更换座位，请联系您的Group Lead</div>
        </div>
      </div>
      <div class="content-r">
        <div class="info">
          <div class="seat-data">
            <span class="font" :class="nowIsExist==='1'?'font-green':'font-dark'">{{ nowIsExist==='1'?nowSeatCode:'您在当前区域无可用座位' }}</span>
            <span>当前座位</span>
          </div>
        </div>
        <div v-if="nowIsExist==='1'" class="date-tip">
          <span>当前座位将使用至：<i>{{ nowSeatEndTime }}</i></span>
          <span>剩余<i>{{ surplusDays }}</i>天</span>
        </div>
        <div v-if="nowIsExist!=='1'" class="date-tip center">
          <span v-if="floorExist!=='0'">预计座位开始使用时间：<i>{{ preStartTime }}</i></span>
          <span v-else></span>
        </div>
        <div class="seat-list">
          <div class="tt">我的座位</div>
          <ul>
            <template v-for="(item,index) in mySeatCommandList" :key="index">
              <li>
                <a-descriptions class="descriptions">
                    <a-descriptions-item label="我的座位" :span="24">{{ item.seatCode }}</a-descriptions-item>
                    <a-descriptions-item label="使用日期" :span="24">{{ item.startDate }}至{{ item.endDate }}<span :class="item.seatNowStatus==='1'?'font-green':'font-blue'">{{item.seatNowStatus==='1'?'（使用中）':'（未开始）'}}</span></a-descriptions-item>
                    <a-descriptions-item label="Group Lead" :span="24"><span class="font-blue">{{ item.groupLeadName }}（{{ item.groupLeadEmail }}）</span></a-descriptions-item>
                    <a-descriptions-item label="Office" :span="24">{{ item.office }}</a-descriptions-item>
                </a-descriptions>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!isExist" class="no-data">
      <img style="width: 390px;" src="@/assets/images/no_data_bg.png">
      <div class="no-data-tip">
        <span class="font-big">未查询到可用座位信息</span>
        <span>如需使用座位，请联系您的上级</span>
      </div>
    </div>
  </section>
</template>
<script>
import {ref, defineComponent, onMounted} from "vue"
//import { useGlobalPropertyHook } from "@/hooks/common"
import Filter from "./components/Filter.vue";
import SeatMaps from "../components/SeatMaps.vue"
export default defineComponent({
  name: "mySeat",
  components: {
    Filter,
    SeatMaps,
  },
  setup(props,ctx) {
    console.log(props,ctx,123)
    //const { $api } = useGlobalPropertyHook();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    console.log(userInfo,'userInfo')
    const seatFilterRef = ref()
    const seatArr = ref([])
    const seatData = ref([])
    const formSeatArr = ref()
    const locationName = ref()

    const isExist = ref()
    const nowIsExist = ref()
    const nowSeatCode = ref()
    const surplusDays = ref()
    const nowSeatEndTime = ref()
    const preStartTime = ref()
    const mySeatCommandList = ref()
    const floorExist = ref()
    const floorSeatCode = ref()

    const handleSearch = (data) => {
      nowIsExist.value = data.nowIsExist
      nowSeatCode.value = data.nowSeatCode
      surplusDays.value = data.surplusDays
      nowSeatEndTime.value = data.nowSeatEndTime
      preStartTime.value = data.preStartTime
      seatData.value = [...data.safetySeatList]
      floorExist.value = data.floorExist
      floorSeatCode.value = data.mySeatCommandList?data.mySeatCommandList[0].seatCode:''
      if(data.type === 1) {
        locationName.value = data.floor
      } else {
        isExist.value = data.isExist==='1'?true:false
        mySeatCommandList.value = [...data.mySeatCommandList]
        locationName.value = data.mySeatCommandList[0].floor
      }
    }
    onMounted(()=>{
    })
    return {
      seatFilterRef,
      seatArr,
      seatData,
      formSeatArr,
      locationName,
      handleSearch,
      isExist,
      nowIsExist,
      nowSeatCode,
      surplusDays,
      nowSeatEndTime,
      preStartTime,
      mySeatCommandList,
      floorExist,
      floorSeatCode,
    };
  },
});
</script>
<style scoped lang="less">
.app-container{
  background: #F7FAFC;
}
.content{
  display: flex;
  gap: 25px;
  width: 100%;
  height: calc(100% - 90px);
  .content-l{
    flex: auto;
    padding: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    background: #fff;
    .left{
      display: inline-block;
      .icon{
        position: relative;
        display: inline-block;
        margin-right: 10px;
        padding-left: 32px;
        line-height: 24px;
        font-weight: 500;
        &:before{
          position: absolute;
          display: block;
          top: 2px;
          left: 0;
          content: '';
          width: 20px;
          height: 20px;
        }
        &.icon_seat_blue{
          &:before{
            background: url("../../../../assets/images/icon_seat_blue.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_green{
          &:before{
            background: url("../../../../assets/images/icon_seat_green.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
        &.icon_seat_white_border{
          &:before{
            background: url("../../../../assets/images/icon_seat_white_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .font-gray{
        font-size: 12px;
        color: #999;
      }
    }
    .right{
      float: right;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      .font-blue{
        padding: 0 4px;
        color: #1890FF;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .seat-box{
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: calc(100% - 34px);
      .lastUpdateTime{
        position: absolute;
        bottom: 16px;
        left: 16px;
        padding: 0 16px;
        height: 38px;
        line-height: 38px;
        border-radius: 4px;
        font-size: 16px;
        background: rgba(245, 245, 245, 0.79);
      }
    }
  }
  .content-r{
    width: 460px;
    .info{
      display: flex;
      gap: 8px;
      width: 100%;
      .seat-data{
        flex: 1;
        height: 130px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
        background: #fff;
        span{
          display: block;
          width: 100%;
          line-height: 22px;
          text-align: center;
          font-size: 16px;
          color: #999;
          &.font{
            margin-top: 20px;
            line-height: 68px;
            font-size: 48px;
            font-weight: 600;
          }
          &.font-green{
            color: #58B99E;
          }
          &.font-dark{
            font-size: 32px;
            color: #333;
          }
        }
      }
    }
    .date-tip{
      display: flex;
      justify-content: space-between;
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      &.center{
        justify-content: center;
      }
      span{
        color: #999999;
        i{
          font-weight: 600;
        }
        &:nth-child(1){
          i{
            color: #1890FF;
          }
        }
        &:nth-child(2){
          i{
            color: #DC5E4F;
          }
        }
      }
    }
    .seat-list{
      width: 100%;
      height: calc(100% - 190px);
      font-size: 16px;
      border-top: 5px solid #3182CE;
      border-radius: 2px ;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      background: #fff;
      .tt{
        position: relative;
        padding: 18px 40px;
        height: 60px;
        line-height: 24px;
        font-weight: 600;
        &:before{
          content: '';
          position: absolute;
          top: 20px;
          left: 24px;
          display: block;
          width: 8px;
          height: 20px;
          background: #3182CE;
          border-radius: 10px;
        }
      }
      ul{
        margin-bottom: 0;
        padding: 0 24px;
        width: 100%;
        max-height: calc(100% - 60px);
        overflow: auto;
        li {
          position: relative;
          margin-top: 24px;
          padding: 0 8px;
          line-height: 40px;
          .descriptions {
            :deep(&.ant-descriptions) {
              font-size: 16px;
              .ant-descriptions-item-label {
                width: 104px;
                color: #4d4d4d;
              }
              .ant-descriptions-item-content {
                font-weight: 600;
                color: #333;
              }
            }
            .font-green{
              color: #58B99E;
            }
            .font-blue{
              color: #1890FF;
            }
          }
        }
      }
    }
  }
}
.no-data{
  img{
    display: block;
    margin: 214px auto 0;
  }
  .no-data-tip{
    margin: -100px auto 0;
    text-align: center;
    line-height: 44px;
    font-size: 28px;
    color: #505050;
    span{
      display: block;
    }
    .font-big{
      line-height: 66px;
      font-size: 44px;
      color: #ababab;
    }
  }
}
</style>
